/* @flow */
import React from 'react';
import classNames from 'classnames';

import { Anchor, Svg, TouchableOpacity } from '@pluralcom/blueprint';

import imgAppStore from './app_store_ badges.svg';
import { mixpanelHelpers } from '../../utils';

import styles from './ButtonAppStore.module.scss';

const ButtonAppStore = ({
  className,
  onClick,
  to,
  mp_location,
  ...rest
}: {
  className?: ?string,
  mp_location?: ?string,
  onClick?: Function,
  to?: string | Object,
}) => (
  <Anchor
    {...(onClick || to
      ? {
          onClick,
          to,
        }
      : {
          href: `https://apps.apple.com/us/app/plural-com/id${process.env.REACT_APP_APP_STORE_ID}`,
          target: '_blank',
        })}
    className={classNames([styles.button, className])}
    // rest props override default behavior
    {...rest}
    onClick={mixpanelHelpers.trackEventAndPass(
      {
        name: `${mp_location ? `${mp_location}` : ''}Button_AppStore`,
      },
      { forceTrack: true },
    )(onClick)}
  >
    <Svg src={imgAppStore} />
  </Anchor>
);

export default ButtonAppStore;
